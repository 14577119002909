/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import 'babel-polyfill'

console.log('Hello World from Webpacker')

require("chartkick")
require("chart.js")
window.$ = $;
//window.jQuery = $;
//import "jquery-ui";

//import "@rails/ujs";
//import "activestorage";
//import {} from 'jquery-ujs'

import Rails from 'rails-ujs';

Rails.start();

import "popper.js";
import 'bootstrap';
//import "colorpicker";
import "jquery-datetimepicker";
import "sifter";
import "microplugin";
import "selectize";
import "../styles/application.scss";
import moment from 'moment'
import flatpickr from "flatpickr";
import { German } from "flatpickr/dist/l10n/de.js"
flatpickr.localize(German);
//= require @fullcalendar/core/main
//= require @fullcalendar/daygrid/main
//= require @fullcalendar/timegrid/main
//= require @fullcalendar/resource-common/main
//= require @fullcalendar/resource-daygrid/main
//= require @fullcalendar/resource-timegrid/main
//= require @fullcalendar/bootstrap/main
//= require tooltip.js/dist/tooltip
//=#import "inputmask/dist/jquery.inputmask.bundle"

import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrapPlugin from '@fullcalendar/bootstrap'
import locales from '@fullcalendar/core/locales-all'
import Tooltip from 'tooltip.js'

console.log(dayGridPlugin)

window.moment = moment
window.Calendar = Calendar
window.dayGrid = dayGridPlugin
window.bootstrap = bootstrapPlugin
window.tooltip = Tooltip
window.Popper = Popper
window.locales = locales

window.jQuery = $

$(function(){
  $('[data-toggle="tooltip"]').tooltip()

  $(".xxxxrender-bootstrap-colorpicker-plus").each(function(index, elem) {
    //console.log("Colorpicker ay")
    $(this).colorpicker({displayIndicator: false, hideButton:false});
    $(this).on("change.color", function(event, color){
      if(color==null) {
        $(this).val('transparent').css('background-color', '#fff');//tranparent
      } else {
        $(this).val(color).css('background-color', color);
      }
    });
  })

  // $(".euro-currency-autoformat").inputmask({
  //   alias: 'numeric', 
  //   groupSeparator: ',', 
  //   digits: 2, 
  //   digitsOptional: false, 
  //   suffix: ' €', 
  //   placeholder: '0'
  // });
})


